import React from "react";
import { Navbar } from "./Navbar";
import useNavigation, { HeaderTheme } from "./useNavigation";
import useValue from "../../../utils/useValue";
import { Link } from "./useNavigation";

interface NavbarContainerProps {
  path: string;
}

const defaultTheme = {
  headerTheme: HeaderTheme.Light,
  mobileHeaderTheme: HeaderTheme.Light,
  tabletHeaderTheme: HeaderTheme.Light,
  sticky: false,
  opacity: false,
};

const NavbarContainer : React.FC<NavbarContainerProps> = ({ path }) => {
   const { links, subLinks } = useNavigation();
   const { value: navbarTheme } = useValue<Link | undefined>('navbar-theme', undefined);
   const theme = navbarTheme?.path === path && !!navbarTheme ?.headerTheme ? navbarTheme : 
      links.find((link) => link.path === path && !!link.headerTheme) || 
      subLinks.find((link) => link.path === path && !!link.headerTheme) ||
      defaultTheme;
   const { headerTheme, sticky: isSticky, opacity: hasOpacity } = theme;

   return (
      <Navbar
         theme={headerTheme!}
         tabletTheme={theme.tabletHeaderTheme || theme.headerTheme}
         mobileTheme={theme.mobileHeaderTheme || theme.headerTheme}
         sticky={!!isSticky}
         opacity={!!hasOpacity}
         isHome={path === "/"}
      />
   );
}

export default NavbarContainer;
