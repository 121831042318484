import React from 'react';
import { FooterNavigation } from './FooterNavigation';
import useValue from '../../../utils/useValue';

export function Footer() {
    const { value: theme } = useValue('theme', 'vibrant-green');
    
    return (
        <footer className="relative z-50 mt-0 md:pb-0 [&_.animated-button]:bg-theme-main [&_.animated-button]:after:bg-theme-2" data-theme={theme}>
            <FooterNavigation />
        </footer>
    );
}
