import React, { Fragment } from "react";
import { Footer } from "../components/base/BaseFooter/Footer";
import MaxWidthWrapper from "../components/MaxWidthWrapper/MaxWidthWrapper";
import NavbarAnimationsProvider from "../components/base/Navbar/NavbarAnimationsProvider";
import ContextValueProvider from "../utils/useContextValue";
import CookieModal from "../components/CookieModal/CookieModal";
import Credits from "../components/Credits";
import NavbarContainer from "../components/base/Navbar/NavbarContainer";

interface Props {
  children: JSX.Element;
}

export default function Layout({ children }: Props) {
  return (
    <>
      <NavbarAnimationsProvider>
        <ContextValueProvider>
          <Fragment>
            <Credits />
            <MaxWidthWrapper
              limit
              className="bg-at-site bg-[length:200%_200%] animate-slow-motion"
            >
              <div className="absolute w-full z-40">
                <MaxWidthWrapper limit>
                  <NavbarContainer path={children?.props.location.pathname || "/"} />
                </MaxWidthWrapper>
              </div>
              <div className="bg-[#eee]">
                {children}
              <Footer />
              </div>
            </MaxWidthWrapper>
          </Fragment>
        </ContextValueProvider>
      </NavbarAnimationsProvider>
      <CookieModal />
    </>
  );
}
