import { useLocation } from '@reach/router';
export enum HeaderTheme {
    Light = "light",
    Dark = "dark",
    LightDark = "light-dark",
    DarkLight = "dark-light",
    DarkAlt = "dark-alt",
    DarkLightAlt = "dark-light-alt"
}

export type Link = {
    id?: number
    path: string
    text?: string,
    headerTheme?: HeaderTheme
    sticky?: boolean
    opacity?: boolean
    mobileHeaderTheme?: HeaderTheme
    tabletHeaderTheme?: HeaderTheme
}

export default function useNavigation() {
    const links = [
        {
            id: 1,
            path: "/",
            text: "Home",
            headerTheme: HeaderTheme.Light
        },
        {
            id: 2,
            path: "/work/",
            text: "Work",
            headerTheme: HeaderTheme.Light
        },
        {
            id: 3,
            path: "/industries/",
            text: "Industries",
            headerTheme: HeaderTheme.Dark
        },
        {
            id: 4,
            path: "/services/",
            text: "Services",
            headerTheme: HeaderTheme.DarkLightAlt,
            mobileHeaderTheme: HeaderTheme.DarkAlt,
            tabletHeaderTheme: HeaderTheme.DarkAlt,
        },
        {
            id: 6,
            path: "/insights/",
            text: "Insights",
            headerTheme: HeaderTheme.LightDark,
            mobileHeaderTheme: HeaderTheme.Dark
        },
        {
            id: 7,
            path: "/careers/",
            text: "Careers",
            headerTheme: HeaderTheme.Dark,
        },
    ];

    const subLinks = [
        {
            id: 8,
            path: "/blogs/",
            text: "Blogs",
            headerTheme: HeaderTheme.Light,
            sticky: true,
        },
        {
            id: 9,
            path: "/on-demand-webinars/",
            text: "Webinar",
            headerTheme: HeaderTheme.Light,
            sticky: true,
        },
        {
            id: 10,
            path: "/case-study/",
            text: "Case Study",
            headerTheme: HeaderTheme.Light,
            sticky: true,
            opacity: true
        },
        {
            id: 11,
            path: "/partners/",
            text: "Partners",
            headerTheme: HeaderTheme.DarkAlt,
            mobileHeaderTheme: HeaderTheme.DarkAlt,
        },
        {
            id: 12,
            path: "/services/",
            text: "Design & Product",
            headerTheme: HeaderTheme.Dark
        },
        {
            id: 13,
            path: "/industry/",
            text: "Design & Product",
            headerTheme: HeaderTheme.Dark
        },
        {
            id: 14,
            path: "/insights-detail/",
            text: "Design & Product",
            headerTheme: HeaderTheme.Dark
        },
        {
            id: 15,
            path: "/agile-podcast/",
            text: "Agile Podcast",
            headerTheme: HeaderTheme.Light,
            sticky: true,
            opacity: true
        },
        {
            id: 16,
            path: "/automation-explanation/",
            text: "Automation Explanation",
            headerTheme: HeaderTheme.Light,
            sticky: true,
            opacity: true
        },
        {
            id: 17,
            path: "/ai-podcast/",
            text: "AI Podcast",
            headerTheme: HeaderTheme.Light,
            sticky: true,
            opacity: true
        },
        {
            id: 18,
            path: "/company-home/",
            headerTheme: HeaderTheme.Dark
        },
        {
            id: 19,
            path: "/privacy-policy/",
            text: "Privacy Policy",
            headerTheme: HeaderTheme.DarkAlt,
            mobileHeaderTheme: HeaderTheme.DarkAlt,
        },
        {
            id: 20,
            path: "/terms-of-use/",
            text: "Terms of use",
            headerTheme: HeaderTheme.DarkAlt,
            mobileHeaderTheme: HeaderTheme.DarkAlt,
        },
    ];


    const caseStudyLink = subLinks.find(link => link.id === 10);
    const caseGuildLink = subLinks.find(link => link.id === 12);
    const caseIndustryLink = subLinks.find(link => link.id === 13);
    const caseBlog = subLinks.find(link => link.id === 8);
    const caseInsightLink = subLinks.find(link => link.id === 14);
    const casePodcastDetail = subLinks.find(link => link.id === 15);

    const location = useLocation();
    if (caseStudyLink && location.pathname.startsWith("/case-study/")) {
        caseStudyLink.path = location.pathname;
        caseStudyLink.text = location.pathname.substring(12);
        caseStudyLink.headerTheme = HeaderTheme.Light;
    }

    if (caseGuildLink && location.pathname.startsWith("/services/")) {
        caseGuildLink.path = location.pathname;
        caseGuildLink.text = location.pathname.substring(12);
        caseGuildLink.headerTheme = HeaderTheme.DarkAlt;
    }

    if (caseIndustryLink && location.pathname.startsWith("/industry/")) {
        caseIndustryLink.path = location.pathname;
        caseIndustryLink.text = location.pathname.substring(13);
        caseIndustryLink.headerTheme = HeaderTheme.DarkAlt;
    }

    if (caseInsightLink && location.pathname.startsWith("/insights-detail/")) {
        caseInsightLink.path = location.pathname;
        caseInsightLink.text = location.pathname.substring(14);
        caseInsightLink.headerTheme = HeaderTheme.DarkAlt;
    }

    if (caseBlog && location.pathname.startsWith("/blogs/")) {
        caseBlog.path = location.pathname;
        caseBlog.text = location.pathname.substring(8);
        caseBlog.headerTheme = HeaderTheme.Light;
    }

    if (casePodcastDetail && location.pathname.startsWith("/agile-podcast/")) {
        casePodcastDetail.path = location.pathname;
        casePodcastDetail.text = location.pathname.substring(15);
        casePodcastDetail.headerTheme = HeaderTheme.Light;
    }

    if (casePodcastDetail && location.pathname.startsWith("/automation-explanation/")) {
        casePodcastDetail.path = location.pathname;
        casePodcastDetail.text = location.pathname.substring(15);
        casePodcastDetail.headerTheme = HeaderTheme.Light;
    }

    if (casePodcastDetail && location.pathname.startsWith("/ai-podcast/")) {
        casePodcastDetail.path = location.pathname;
        casePodcastDetail.text = location.pathname.substring(15);
        casePodcastDetail.headerTheme = HeaderTheme.Light;
    }

    return {
        links,
        subLinks
    } as {
        links: Link[],
        subLinks: Link[]
    };
}

